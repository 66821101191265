<template lang="pug">
  .DateAgended.m-5
    .shadow-bx-small-sm.bg-white.rounded-xl.max-w-sm.mx-auto.p-5
      div.rounded-bd-complete.w-12.h-12.flex.items-center.justify-center.mx-auto.mb-3.mt-0.bg-good_background
        .material-icons-outlined.text-3xl.text-good_color check_circle
      p.text-center.text-primary.mt-5.mb-2
        | Tu cita ha sido agendada
      card-date-resumen(
        :date="drugstoreState"
        :acordionNo="true"
        :adjunto="true"
      ).max-w-sm.mx-auto.list-none
      article.text-center
        p.text-sm.mx-auto.max-w-xs.text-center.maxText
          | Hemos enviado un mail para confirmar tu cita al siguiente correo.
        .Panel.p-3.text-info_color.text-center.my-5
          | {{ emailData }}
        p.text-sm.mx-auto.maxText.text-center.mb-3
          | Gracias por utilizar el servicio de agendamiento Cafam.
        //- a(href="#").text-primary: small Agregar a mi calendario

        p.text-sm.mx-auto.maxText.text-center.mb-3
          b ♦ Recuerda llegar con anticipación a la hora y fecha de la cita seleccionada
        br

        .shadow-bx-small-sm.bg-white.rounded-xl.max-w-sm.mx-auto.p-5.mt-5(v-if="missingFormulas && missingFormulas.length > 0")
          p.text-center.text-primary.mb-2
            | Recuerda que
          article.text-center
            p.text-sm.mx-auto.max-w-xs.text-center.maxText
              | De los números que ingresaste, aún te falta agendar los siguientes:
              p.small-p: small.text-xs.small-text.mx-1.text-secondary(v-for="(numb, i) in missingFormulas" :key="i") {{numb.numero}}
            button-component(
              text="Agendar esta cita"
              themeSelected="secondary"
              :onClick="handleGoHomeAppointments"
            ).maxText.mx-auto.mt-3

        br
        button-component(
          text="Aceptar"
          themeSelected="secondary"
          :onClick="handleGoHome"
        )

</template>

<script>
import { mapState, mapActions } from "vuex";
// import LS from "../helpers/localStorage";

export default {
  name: "DateAgended",
  components: {
    ButtonComponent: () => import("../components/general/Button.vue"),
    CardDateResumen: () => import("../components/CardDateResumen.vue"),
  },
  async created(){
    // set on localstorage
    // let user = LS.getItem('user_info');
    // user = user ? user:[];
    // user = user.filter(u=>u.documentNumber!=this.userData.documentNumber);
    // user.push({
    //   name: this.userData.name,
    //   documentNumber: this.userData.documentNumber,
    //   documentType: this.userData.documentType,
    //   dcType:  this.userData.dcType,
    //   cityId: this.userData.cityId,
    //   cityOrMunicipio: this.userData.cityOrMunicipio,
    //   email: this.user.email,
    //   phone: this.user.phone,
    //   address: this.user.address
    // });
    // LS.setItem("user_info", user);
    
    await this.postUsers({
      id: this.user.id || this.userData.id,
      name: this.user.name || this.userData.name,
      document: this.user.documentNumber || this.userData.documentNumber,
      documentType: this.user.dcType || this.userData.dcType,
      dcType:  this.user.dcType || this.userData.dcType,
      cityId: this.user.cityId || this.userData.cityId,
      cityOrMunicipio: this.userData.cityOrMunicipio,
      email: this.user.email || this.userData.email,
      phone: this.user.phone || this.userData.phone,
      address: this.user.address || this.userData.address
    });
    
    // fetch all users
    await this.getUsers({
      document: this.userData.documentNumber
    });
    
    let user = this.userList.find(u=>u.document == this.userData.documentNumber)

    // Send data processing
    this.setProcessing({
      document: this.processingData.document,
      timestamp_click: this.processingData.timestamp_click,
      appointment_code: this.responseAppointment.code,
      action: 'Aceptación'
    });

    // set new data user
    if(user){
      this.changeUserData({
        id: user.id
      });
    }

    setTimeout(() => {
      
      if(this.missingFormulas < 1){
        // reset store
        this.reset();
      }
      
    }, 1000);
    

  },
  computed: {
    ...mapState({
      user: (state) => state.user.userData,
      drugstoreState: (state) => state.drugstore.drugstoreState,
      formDocuments: (state) => state.formDocuments.formDocumentsState,
      env: (state) => state.environment.environment.env,
      userData: (state) => state.root.userData,
      appointments: (state) => state.appointments.appointments,
      userList: (state) => state.environment.userList,
      processingData: (state) => state.environment.processing,
      responseAppointment: (state) => state.appointments.responseAppointment,
    }),
    emailData() {
      return this.user.email || this.userData.email || "No hay email";
    },
    missingFormulas(){
      return this.formDocuments.filter(doc => !this.drugstoreState.numbers.includes(doc.numero) )
    }
  },
  methods:{
    ...mapActions("root", [
      "changeTrip",
      "handleShowModal",
      "changeUserData"
    ]),
    ...mapActions("formDocuments", [
      "setFormDocuments",
      "reset"
    ]),
    ...mapActions([
      "fetchAppointments", "postUsers", "getUsers", "setProcessing"
    ]),
    handleGoHomeAppointments(){
      this.setFormDocuments(this.missingFormulas);
      this.changeTrip('Home_appointments')
    },
    async handleGoHome(){

      let queryData = '?customer.dni='+ this.userData.documentNumber + "&status=CONFIRMED";
      await this.fetchAppointments({
        url: this.env.VUE_APPOINTMENTS_DEBMEDIA_SERVER,
        headers: {
          'token_appointments': this.env.VUE_APPOINTMENTS_DEBMEDIA_API_KEY,
        },
        queryData: queryData
      });

      this.setFormDocuments(this.missingFormulas);

      setTimeout(() => {
        if(this.appointments.length > 0){
          this.changeTrip('Home_dates');
        }else this.changeTrip('Home');
      }, 1000);
      
    }
  }
}
</script>

<style scoped>
.Panel {
  background: rgba(150, 150, 150, 0.116);
}

.maxText {
  max-width: 250px;
}
</style>